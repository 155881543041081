.uploadpage-wrapper {
    background-color: #F7F8FA;
    font-family: Helvetica;
    padding-top: 10px;
    // height: 78.8vh;
    .react-bootstrap-table-pagination {
        margin-bottom: 3vw;
        margin-right: 0;
    margin-left: 0;
    }
    .PrivateTabIndicator-colorSecondary-6 {
        background-color: transparent;
    }
    #simple-tab-2, #simple-tabpanel-2 {
        .MuiBox-root-14, .MuiBox-root-16, .MuiBox-root-21 {
            padding: 0;
        }
    }
    .MuiGrid-spacing-xs-3{ 
        width: 100%;
        margin: unset;
        .MuiGrid-item {
            padding: 0;
        }
    }
    .MuiBox-root {
        padding: 0!important;
    }
    
}

.upload-process-container {
    .footer {
        position: fixed;
    }
}

.tabs-bar {
    width: 52vw;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000033;
    border: 0;
    border-radius: 4px;
    color: black;
    .MuiTabs-scroller {
        height: 6vh !important;
    }
    span {
        background-color: transparent !important; 

        // &:before {
        //     border-width: 14px;
        //     display: block;
        //     border-color: transparent transparent transparent #ffffff;
        //     z-index: 2;
        //     top: 1px;
        // }

        // &:after {
        //     border-color: transparent transparent transparent #d68a3a;
        // }
    }
    // button:before {
    //     border-width: 14px;
    //     display: block;
    //     border-color: transparent transparent transparent #ffffff;
    //     z-index: 2;
    //     top: 1px;
    // }

    // button:after {
    //     border-color: transparent transparent transparent #d68a3a;
    // }
}

.Mui-selected {
    opacity: 1;
    background: #0C629B;
    color: #FFFFFF;
}

.PrivateTabIndicator-colorSecondary-5 {
    background-color: transparent !important;
}

.PrivateTabIndicator-colorSecondary-23 {
    background-color: transparent !important;
}

.MuiTab-textColorInherit.Mui-selected {
    background: #0C629B;
    color: #ffffff;
    border-radius: 3%;
}

.MuiTab-root {
    min-width: 213px !important;
    text-transform: inherit;
}

.draganddrop {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    .MuiLinearProgress-barColorPrimary {
        background-color: #74C323;
    }
    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        color: #74C323;
    }
}

.draganddrop-progress {
    width: 50vw;
    height: 50vh;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    overflow-y: scroll;
    overflow-x: hidden;
    .MuiLinearProgress-barColorPrimary {
        background-color: #74C323;
    }
    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        color: #74C323;
    } 
}

.process {
    height: 2vw;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
}

.process-page {
    .MuiBox-root {
        padding: 0!important;
    }
}

.uploadNote {
    font-size: 1vw;
    margin-top: 0.26vw;
    margin-left: 12.7vw;
    span {
        font-weight: 600;
    }
}