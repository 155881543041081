$font-family: Helvetica;
$background-color: #FFFFFF;
$box-shadow-color: #00000041;
$border-color: #707070;
$text-color-black: #000000;
$grey-1: #898989;
$primary-color: #0C629B;
$tag-background-color: #eff5f8;
$result-background: #EFF0F2;
$ccc-color: #CCCCCC;
$font-size-14px: 0.73vw;
$font-size-16px: 0.83vw;
$font-size-17px: 0.88vw;
$font-size-18px: 0.93vw;
$font-size-20px: 1.04vw;
$font-size-24px: 1.25vw;
$font-size-30px: 2.34vw;
$font-size: 1vw;
$font-size-1: 1.1vw;
$font-size-2: 1.2vw;
$font-size-3: 1.3vw;