@import "../../../../../GlobalVriables.scss";

.item {
  margin: 1em auto;
}

.pager {
  max-width: fit-content;
}

.page-item.active .page-link {
  background-color: $primary-color;
  border-color: $primary-color;
}

.page-link {
  color: $primary-color;
}

.page-item {
  font-size: $font-size-20px;
}
