@import "../../../GlobalVriables.scss";

.overallMTO-container{
  height: 100vh;
  .footer {
    top:100vh;
  }
.overallMTO-wrapper {
  background-color: #f7f8fa;
  font-family: Helvetica !important;
  .toolbar {
    padding-top: 1vw;
    padding-bottom: 1vw;
    display: flex;
    justify-content: space-between;
    .back-btn-pid {
      cursor: pointer;
      // text-decoration: underline;
      color: #0c629b;
      margin-left: 1.5vw;
      .back-icon {
        height: 1vw;
      }
      .toolbar-text {
        margin-left: 0.5vw;
        font-size: $font-size-18px;
        color: #0c629b;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    

    .print-MTO {
      cursor: pointer;
      color: #0c629b;
    //   text-decoration: underline;
      margin-right: 1.5vw;
      // margin-top: 0.5vh;
      .print-mto-icon {
        height: 1vw;
      }
      .toolbar-text {
        margin-left: 0.5vw;
        font-size: $font-size-18px;
        color: #0c629b;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.overallMTO-wrapper {
  // margin-top: 2vw;
  // width:100%;
  // overflow: scroll;
  // height: 34vw;
  .export-csv {
    width: 13vw;
    margin-top: -1.8vw;
    margin-left: 72vw;
  }

  .MTO-table-wrapper {
    // .table-pid-mto {
      width:100%;
  overflow: scroll;
  height: 35vw;
  &::-webkit-scrollbar {
    width: 0.8vw;         
    height: 0.8vw;     
}
&::-webkit-scrollbar-track {
    background: #E8EAEB;        
}
  
&::-webkit-scrollbar-thumb {
    background-color: #808080;    
    border-radius: 20px;       
}
    // }
  }

.MTO-Table {
  width:100%;
  overflow: scroll;
  height: 32vw;
  &::-webkit-scrollbar {
    width: 0.8vw;         
    height: 0.8vw;     
}
&::-webkit-scrollbar-track {
    background: #E8EAEB;        
}
  
&::-webkit-scrollbar-thumb {
    background-color: #808080;    
    border-radius: 20px;       
}
  .react-bootstrap-table {
    margin-top: 1vw;
    font-family: Helvetica !important;
    .react-bootstrap-table-pagination {
      margin-left: 0.1vw !important;
    }
    .table {
      // width: min-content;
     
      thead {
        background-color: #e8eaeb;
        text-align: center;
        font-size: $font-size-18px;
        border-top: 0.3vw solid #0c629b;
        position: sticky;
         top: -3px;
        th{
          width: 16vw;
        }
        tr {
          font-size: 0.75vw;
        }
        th {
          vertical-align: middle;
          border-left: 0px;
          border-right: 0px;
          // width: 5.5%;
          // white-space: nowrap;
        }
        // th:nth-child(1) {
        //   width: 14%;
        // }
        // th:nth-child(2) {
        //   width: 6.5%;
        // }
        // th:nth-child(3) {
        //   width: 7%;
        // }
        // th:nth-child(4) {
        //   width: 8%;
        // }
        // th:nth-child(13) {
        //   width: 13%;
        // }
      }
      tbody {
        text-align: center;
        background-color: white;
        font-size: $font-size-16px;
        letter-spacing: 0em;
      }
      td {    
        vertical-align: middle;
        border-left: 0px;
        border-right: 0px;
        text-overflow: ellipsis;
        width: 50%;
      }
    }
  }
}
}
  .download-MTO {
    cursor: pointer;
    color: #0c629b;
    // text-decoration: underline;
    margin-left: 68vw;
    // margin-top: -7.5vh;
    .download-icon {
      height: 1vw;
    }
    .toolbar-text {
      margin-left: 0.5vw; 
      font-size: $font-size-18px;
      color: #0c629b;
      &:hover {
        opacity: 0.6;
      }
    }
  }
//   .Overall-MTO-Table {
//     height: 32vw;
//     width: 100%;
//     overflow: scroll;
//     &::-webkit-scrollbar {
//       width: 0.8vw;         
//       height: 0.8vw;     
//   }
//   &::-webkit-scrollbar-track {
//       background: #E8EAEB;        
//   }
    
//   &::-webkit-scrollbar-thumb {
//       background-color: #808080;    
//       border-radius: 20px;       
//   }
  
  
// }
// .react-bootstrap-table {
//   margin-top: 1vw;
//   font-family: Helvetica !important;
//   .react-bootstrap-table-pagination {
//     margin-left: 0.1vw !important;
//   }
//   .table {
//     // width: min-content;
   
//     thead {
//       background-color: #e8eaeb;
//       text-align: center;
//       font-size: $font-size-18px;
//       border-top: 0.3vw solid #0c629b;
//       th{
//         width: 16vw;
//       }
//       tr {
//         font-size: 0.75vw;
//       }
//       th {
//         vertical-align: middle;
//         border-left: 0px;
//         border-right: 0px;
//         // width: 5.5%;
//         // white-space: nowrap;
//       }
//       // th:nth-child(1) {
//       //   width: 14%;
//       // }
//       // th:nth-child(2) {
//       //   width: 6.5%;
//       // }
//       // th:nth-child(3) {
//       //   width: 7%;
//       // }
//       // th:nth-child(4) {
//       //   width: 8%;
//       // }
//       // th:nth-child(13) {
//       //   width: 13%;
//       // }
//     }
//     tbody {
//       text-align: center;
//       background-color: white;
//       font-size: $font-size-16px;
//       letter-spacing: 0em;
//     }
//     td {    
//       vertical-align: middle;
//       border-left: 0px;
//       border-right: 0px;
//       text-overflow: ellipsis;
//       width: 50%;
//     }
//   }
// }
}

@page {
  // size: A3;
  margin: 1mm 1mm 1mm 1mm;
  // size: landscape;
  size: 800mm 500mm; 
  // orientation: portrait;
}
// @media print and (orientation: landscape) {
//   /* landscape styles */
//   /* write specific styles for landscape e.g */
//   h1 {
//        color: #000;
//        background: none;
//     }

//     nav, aside {
//        display: none;
//     }

//  }
@media print {
  .clear-filter {
    display: none;
  }
  th:nth-child(13) {
    width: 6% !important;
  }
  th:nth-child(1) {
    width: 6% !important;
  }
  th:nth-child(2) {
    width: 5% !important;
  }
  th:nth-child(3) {
    width: 5% !important;
  }
  th:nth-child(4) {
    width: 5% !important;
  }
  th:nth-child(10) {
    width: 6% !important;
  }
  th:nth-child(20) {
    width: 5% !important;
  }
  th:nth-child(19) {
    width: 5% !important;
  }
  td:nth-child(1) {
    // width: 10% !important;
    word-wrap: break-word; 
  }
  th,td:nth-child(2) {
    // width: 5%; 
    word-wrap: break-word; 
  }
  th,td:nth-child(3) {
    // width: 5%;
    word-wrap: break-word; 
  }
  td:nth-child(13) {
    // width: 7%;
    word-wrap: break-word; 
  }
  th,td:nth-child(18) {
    // width: 7%;
    word-wrap: break-word; 
  }
  td:nth-child(19) {
    // width: 7%;
    word-wrap: break-word; 
  }
  td:nth-child(20) {
    // width: 7%;
    word-wrap: break-word; 
  }
  
  th,td:nth-child(21) {
    // width: 7%;
    word-wrap: break-word; 
  }
  

}

