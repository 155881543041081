.drag-drop-box-container {
    position: relative;
    
    .cloud-icon {
        height: 17vh;
    }
    &::-webkit-scrollbar {
        width: 0.8vw;         
        height: 0.8vw;     
    }
    &::-webkit-scrollbar-track {
        background: #E8EAEB;        
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: #808080;    
        border-radius: 20px;       
    }
}

.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .supported-format-text {
        color: #74777A;
        font: normal normal 300 0.8vw Helvetica;
    }
    .dragdrop-text {
        font: normal normal 300 1vw Helvetica;
        text-align: center;
    }
    .choose-files-btn {
        border-radius: 0.3vw;
        width: 9vw;
        height: 2.3vw;
        cursor: pointer;
        font-size: 1.1vw;
        text-align: center;
        padding: .3vw 0;
        margin: 0;
    }
    input[type="file"] {
        display: none;
    }
}
