.add-line-popup {
  .modal-content {
    background-color: #0c629b !important;
    color: #fff !important;
    width: 23vw !important;
    .line-modal-body {
      background-color: #0c629b !important;
      text-align: inherit;
      .input-field-line {
        width: 20vw !important;
        color: black;
        border: 0px;
      }
    }
    .MuiButton-root {
      background-color: #fff !important;
      color: #0c629b !important;
      text-transform: none;
    }
    .modal-footer {
      border-top: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .label-text-line {
      color: #fff;
      font-size: 1vw;
      // margin-right: 30vw;
    }
    .add-line-text {
      cursor: pointer;
      font-size: 1vw;
      text-decoration: underline;
      // margin-right: 29vw;
    }
    .add-new-line {
      .MuiInputBase-root {
        background-color: #fff !important;
        width: 15vw !important;
        height: 1.5vw !important;
        border-radius: 0.1vw !important;
      }
    }
  }
}


