.Preview-screen-container {
    background-color: #F7F8FA;
    font-family: Helvetica;
    overflow-x: hidden;
}

.accept-btn-pid {
    display: block;
    background: #0C629B 0% 0% no-repeat padding-box;
    border: 1px solid #0C629B;
    border-radius: 4px;
    color: #FFFFFF;
    width: 8.4vw;
    height: 2.29vw;
    margin-right: 1.3vw;
    font: normal normal normal 0.78vw Helvetica;
    text-transform: none;
}

.back-btn-pid {
    margin-left: 1.4vw;
    // margin-bottom: 1vw;
    margin-top: 0.2vw;
    &:hover {
        opacity: 0.8;
    }
    .back-icon {
        width: 1.3vw;
        cursor: pointer;
    }
    .text {
        color: #0C629B;
        margin-left: 1vw;
        font: normal normal normal 1.1vw Helvetica;
        cursor: pointer;
    }
}

.image-annotator-container {
    display: flex;
    justify-content: center;
    // margin-bottom: 2vw;
    .MTO-report-container {
        width: 50vw;
        height: 80vh;
        padding: 0.5vw;
        .grid-wrapper {
            height: 70vh;
            .MTO-toolbar {
                display: flex;
                .select-all-preview-pid {
                    label + .MuiInput-formControl {
                        width: 8vw;
                    }
                }
                .text {
                    color: #0C629B;
                    margin-left: 1vw;
                    font: normal normal normal 1vw Helvetica;
                }
                .tool-icon {
                    margin-left: 1vw;
                }
                .view-MTO {
                    margin-left: -1vw;
                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }
                }
                .expand-PID {
                    margin-left: 29vw;
                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }
                }
                .reprocessing {
                    margin-left: 3vw;
                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .MTO-table {
        height: 60vh;
        margin-top: 1vw;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 0.8vw;         
            height: 0.8vw;     
        }
        &::-webkit-scrollbar-track {
            background: #E8EAEB;        
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #808080;    
            border-radius: 20px;       
        }
    }
    .btns-pid{
        display: flex;
        justify-content: center;
        padding-top: 1.2vw;
    }
    .react-bootstrap-table>table>thead>tr>th .multiSelectContainer {
        margin-top: 1vw;
        // width: 15.5vw;
        .optionListContainer .optionContainer{
            font-size: 0.9vw;
        }
    }
    .react-bootstrap-table {
        width: 58vw !important;
        margin-left: 0 !important;
        
        .table {
            // height: 58vh;
            thead {
                background-color: #E8EAEB;
                text-align: center;
                font-size: 1vw;
                border-top: 0.3vw solid #0C629B;
                position: sticky;
                top: -3px;
                th {
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                    width: 16vw;
                    line-height: 0.3vw;
                    // white-space: nowrap;
                }
                th:nth-child(2),
                 th:nth-child(3),  th:nth-child(7), th:nth-child(11) , th:nth-child(8), th:nth-child(9) , th:nth-child(12), th:nth-child(14) , th:nth-child(18) {
                    width: 15vw;
                }
                th:nth-child(4),th:nth-child(10) {
                    width: 10vw;
                } 
                th:nth-child(5), th:nth-child(13), th:nth-child(16), th:nth-child(17),  th:nth-child(6) {
                    width: 12vw;
                }
                th:nth-child(15) {
                    width: 10vw;
                }
            }
            tbody {
                text-align: center;
                font: normal normal normal 1vw Helvetica;
                letter-spacing: 0.27px;
                // table-layout:fixed;
                tr{
                    line-height: 1vw !important;
                }
            }
            td {
                vertical-align: middle;
                border-left: 0px;
                border-right: 0px;
                // white-space: nowrap;
            }
        }
    }
}

.expand-window-MTO {
    .modal-content {
        border: none !important;
        .modal-body{
        height: 80vh;
        max-height: 80vh;
        overflow: scroll;
        padding: 0;
        font-size: 0.9vw;
        // width: 86.5vw;
        min-width: 50vw !important;
        max-width: 86vw !important;
        margin-left: 1vw;
        &::-webkit-scrollbar {
            width: 0.8vw;         
            height: 0.8vw;     
        }
        &::-webkit-scrollbar-track {
            background: #E8EAEB;        
        }
        &::-webkit-scrollbar-thumb {
            background-color: #808080;    
            border-radius: 20px;       
        }
    }
        .modal-header {
            height: 3vw;
            background-color: #0C629B;
            .close-icon-mto {
                cursor: pointer;
                color: white;
                height: 1.5vw;
                width: 1.5vw;
                position: absolute;
                margin-top: -0.3vw;
                margin-left: 95%;
            }
        }

        .MTO-table {
            height: 80vh;
            max-height: 80vh;
            padding: 1vw;
            .react-bootstrap-table {
                .table{
                    thead {
                        th{
                            width: 16vw;
                        }
                    }
                }
            }
        }
    }
}