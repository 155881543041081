@import '../../../GlobalVriables.scss';

.regexp-container {
    // .react-bootstrap-table-pagination {
    //     padding: 0;
    //     margin: 0;
    // }

    

    .footer-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .regexp-btn-fields {
        position: absolute;
        left: 38vw;
        bottom: 4vw;
    }
    .next-btn {
        width: 8.4vw;
        height: 2.29vw;
        font-family: Helvetica;
        font-size: 0.9vw;
    }
    .MuiInputBase-input {
        font-size: 1.04vw;
    }
    .regexp-popup {
        #regexp-form-dialog-title {
            .MuiTypography-h6{
                font-size: 1.5vw;
            }
        }
        .MuiFormControl-fullWidth {
            width: 95%;
        }

        .MuiDialog-paperWidthSm {
            max-width: unset;
        }
    }

    .regexp-worspace-titlebar {
        display: flex;
        padding: 1.5vw;
        flex-direction: row;
        justify-content: space-between;
        font-family: Helvetica;
        .regexp-workspace-title {
            font-size: 1.145833333vw;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
    .regexp-workspace-data-table-wrapper {
        
        .react-bootstrap-table {
            width: 50vw;
            .table {
                background-color: $background-color;
                thead {
                    background-color: #E8EAEB;
                    text-align: center;
                    height: 4vw !important;
                    border-top: 0.3vw solid #0c629b;
                    th {
                        font-size: 0.9375vw;
                        text-align: left;
                        padding-left: 1vw;
                        vertical-align: middle;
                        border-left: 0px;
                        border-right: 0px;
                    }
                    th:nth-child(2) {
                        width: 13vw;
                    }
                    th:nth-child(2) {
                        padding-left: 1vw;
                        padding-right: 0;
                    }
                    
                }
                tbody {
                    text-align: center;
                    font: normal normal normal 0.729vw Helvetica;
                    letter-spacing: 0.27px;
                }
                tr {
                    height: 3vw !important;
                }
                td {
                    vertical-align: middle;
                    font-size: 0.9375vw;
                    text-align: left;
                    padding-left: 1vw;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                }
                td:nth-child(2) {
                    word-break: break-all;
                }
                
            }
        }
    }


    .regexp-workspace-data-table-wrapper1 {
        margin-left: 1vw;
        
        .react-bootstrap-table {
            width: 50vw;
            .table {
                background-color: $background-color;
                thead {
                    background-color: #E8EAEB;
                    text-align: center;
                    height: 4vw !important;
                    border-top: 0.3vw solid #0c629b;
                    th {
                        font-size: 0.9375vw ;
                        text-align: left;
                        padding-left: 1vw;
                        vertical-align: middle;
                        border-left: 0px;
                        border-right: 0px;
                    }
                    th:last-child {
                        text-align: center;
                        width: 8vw;
                    }
                    th:nth-child(2) {
                        width: 7vw;
                    }
                    th:nth-child(1) {
                        width: 7vw;
                    }
                    th:nth-child(3) {
                        width: 18vw;
                    }
                }
                tbody {
                    text-align: center;
                    font: normal normal normal 0.729vw Helvetica;
                    letter-spacing: 0.27px;
                }
                tr {
                    height: 3vw !important;
                }
                td {
                    vertical-align: middle;
                    font-size: 0.9375vw ;
                    text-align: left;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                    
                }
                td:last-child {
                    text-align: center;
                }
                td:nth-child(3) {
                    word-break: break-all;
                    padding-right: 0;
                }
                td:nth-child(1) {
                    word-break: break-all;
                }
                
            }
        }
    }
    .regexp-btn-fields {
        margin-left: 1vw !important;
        margin-top: 0.5vw !important;
    }
}

.regexp-popup {
   .MuiDialog-paperWidthSm {
        max-width: unset;
    }
}