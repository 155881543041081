@import '../../../GlobalVriables.scss';

.businessrule-container {
    .react-bootstrap-table-pagination {
        padding: 0;
        margin: 0;
    }
    .footer-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .btn-fields {
        position: absolute;
        left: 38vw;
        bottom: 4vw;
    }
    .next-btn {
        width: 8.4vw;
        height: 2.29vw;
        font-family: Helvetica;
        font-size: 0.9vw;
    }
    .MuiInputBase-input {
        font-size: 1.04vw;
    }
    .workspace-data-table-wrapper {
        .react-bootstrap-table {
            width: 100vw;
            max-height: 50vh;
            overflow: auto;
            .table {
                background-color: #FFFFFF;
                thead {
                    background-color: #E8EAEB;
                    text-align: center;
                    height: 4vw !important;
                    th {
                        font-size: 1vw;
                        text-align: left;
                        padding-left: 1.5vw;
                    }
                }
                tbody {
                    text-align: center;
                    font: normal normal normal 14px Helvetica;
                    letter-spacing: 0.27px;
                }
                tr {
                    height: 3vw !important;
                }
                td {
                    vertical-align: middle;
                    font-size: 1vw;
                    text-align: left;
                    padding-left: 1.5vw;
                }
            }
        }
    }
    .businessrule-popup {
        #form-dialog-title {
            .MuiTypography-h6{
                font-size: 1.5vw;
            }
        }
        .MuiFormControl-fullWidth {
            width: 95%;
        }
    }
    .workspace-data-table-wrapper {
        .react-bootstrap-table {
            width: 100vw;
            .table {
                background-color: $background-color;
                thead {
                    background-color: #E8EAEB;
                    text-align: center;
                    height: 4vw !important;
                    border-top: 0.3vw solid #0c629b;
                    th {
                        font-size: 1vw;
                        text-align: left;
                        padding-left: 4vw;
                        vertical-align: middle;
                        border-left: 0px;
                        border-right: 0px;
                    }
                    
                }
                tbody {
                    text-align: center;
                    font: normal normal normal 0.729vw Helvetica;
                    letter-spacing: 0.27px;
                }
                tr {
                    height: 3vw !important;
                }
                td {
                    vertical-align: middle;
                    font-size: 1vw;
                    text-align: left;
                    padding-left: 4vw;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                }
                
            }
        }
    }
    .btn-fields {
        margin-left: 1vw !important;
        margin-top: 0.5vw !important;
    }
}

