.home-page-wrapper {
    margin: 14.5vw auto;
    max-width: 45%;
    display: block;
    .welcome-msg {
        margin-bottom: 7vh;
        font-size: 1.3vw;
        text-align: center;
    }
}

.home-page {
    .footer-wrapper {
        position: fixed;
        bottom: 0;
        width:100%;
    }
}
.footer {
   position: sticky;
   left: 0;
   bottom: 0;
   width: 100%;
   z-index: 10;
}

.usecase-links-row {
    display: flex;
    justify-content: center;
}

.usecase-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #00000019;
    border: 0.25vw solid #0C629B;
    border-radius: 0.4vw;
    opacity: 1;    
    margin-left: 2vw;
    padding: 0;
    width: 17.44vw;
    height: 8vw;
    text-align: center;
    cursor: pointer;
    p {
        font: normal normal bold 1vw Helvetica;
        letter-spacing: 0px;
        color: #0C629B;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 0vw ;
    }
    .SR-svg, .PID-svg {
        width: 3.07vw;
        height: 3vw;    
        margin: 0.5vw;
        margin-top: 1.4vw;
    }
}

.usecase-link:hover {
    background: #0C629B 0% 0% no-repeat padding-box;
    p {
        color: #FFFFFF;
    }
    img {
        color: #FFFFFF;
    }
    svg path { 
        fill: #FFFFFF;
        // stroke: #FFFFFF;
    }
}

.PID:hover {
    svg path { 
        stroke: #FFFFFF;
    }
}
