@import "../../../GlobalVriables.scss";

// .table-filter {
//     font-size: 1vw;
//     background-color: #fff;
//     //   th:nth-child(even) {
//     //     max-width: 20vw;
//     // }
//     .table-filter-icon {
//       margin-right: 1vw;
//     }
//   }

.table-pid-mto {
    .table-head-pid {
      background-color: #E8EAEB;
      text-align: center;
      font-size: 1vw;
      border-top: 0.3vw solid #0C629B;
      position: sticky;
      top: -3px;
      th {
        vertical-align: middle;
        border-left: 0px;
        border-right: 0px;
        width: 20vw;
        line-height: 1.3vw;
        .table-filter-parent {
            .filter-list {
              max-width: fit-content;
            }
          }
        .resize-div {
          // width: 10vw;
          text-align: center;
          resize: horizontal;
        overflow: auto;
        min-width: 9vw;
        // font-weight: 500;
        text-align: center;
        font-size: 1vw;
        // padding-left: 1.5vw;
        }
        // white-space: nowrap;
    }
    // th:nth-child(2),
    //  th:nth-child(3),  th:nth-child(7), th:nth-child(11) , th:nth-child(8), th:nth-child(9) , th:nth-child(12), th:nth-child(14) , th:nth-child(18) {
    //     width: 15vw;
    // }
    // th:nth-child(4),th:nth-child(10) {
    //     width: 10vw;
    // } 
    // th:nth-child(5), th:nth-child(13), th:nth-child(16), th:nth-child(17),  th:nth-child(6) {
    //     width: 12vw;
    // }
    // th:nth-child(15) {
    //     width: 10vw;
    // }
    tbody {
      text-align: center;
      font: normal normal normal 1vw Helvetica;
      letter-spacing: 0.27px;
      margin-top: 0.5vw;
      // table-layout:fixed;
      tr{
          line-height: 1vw !important;
      }
      td {
        text-align: center;
        // border-left: 0px;
        // border-right: 0px;
        // // white-space: nowrap;
    }
  }
  
    }
  }



  .clear-filter {
    cursor: pointer;
    color: #0c629b;
    position: absolute;
    right: 26vw;
    z-index: 10;
    top: 17.3vh;
    .clear-filter-icon {
        height: 1vw;
    }
    .toolbar-text {
      margin-left: 0.5vw; 
      font-size: $font-size-18px;
      color: #0c629b;
      &:hover {
        opacity: 0.6;
      }
    }
  }