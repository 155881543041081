.suggestion-item:hover {
    background-color: #e9e9e9;
    cursor: pointer;
}

.suggestions-box {
    background-color: #fff;
    position: absolute;
    z-index: 1400;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 90%;
    height: 8vw;
    &::-webkit-scrollbar {
        width: 0.8vw;         
        height: 0.8vw;     
    }
    &::-webkit-scrollbar-track {
        background: #E8EAEB;        
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: #808080;    
        border-radius: 20px;       
    }
}

