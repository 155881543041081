.uploadpage-wrapper {
    background-color: #F7F8FA;
    font-family: Helvetica;
    padding-top: 1vw;
    // height: 78.8vh;
    .react-bootstrap-table-pagination {
        margin-bottom: 3vw;
    }
}

.upload-process-container-pid{
    .footer {
        position: fixed;
    }
}

.tabs-bar {
    width: 52vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000033;
    border-radius: 4px;
    color: black;
    border: 0;
    margin-bottom: 1vw;

    .MuiTabs-scroller {
        height: 6vh !important;
    }
    .MuiTabs-root {
        min-height: 2.8vw;
    }
    .MuiTab-root {
        min-width: 17.3vw !important;
        min-height: 6vh;
    }
    .MuiTab-wrapper {
        font-size: 0.9vw;
        font-weight: bold;
    }
    .Mui-selected {
        opacity: 1;
        background: #0C629B;
        color: #FFFFFF;
    }
    .PrivateTabIndicator-colorSecondary-5 {
        background-color: white !important;
    }
    .PrivateTabIndicator-colorSecondary-23 {
        background-color: white !important;
    }
    .MuiTab-textColorInherit.Mui-selected {
        background: #0C629B;
        color: #ffffff;
        border-radius: 3%;
    }
}


.draganddrop {
    //height: 63vh;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    position: relative;
    .MuiLinearProgress-barColorPrimary {
        background-color: #74C323;
    }
    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        color: #74C323;
    }
}

.draganddrop-progress {
    width: 50vw;
    height: 50vh;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    overflow-x: hidden;
    overflow-y: scroll;
    .MuiLinearProgress-barColorPrimary {
        background-color: #74C323;
    }
    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        color: #74C323;
    }   
}

.process {
    height: 20em;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #EDEDED;
    border-radius: 2px;
}
