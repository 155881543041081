.breadCrumbs {
  padding: 0.5vw;
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1vw solid rgb(222, 226, 230);
  height: 3.56vw;
  align-items: center;
  font-weight: 700;

  .MuiTypography-body1 {
    color: #00629b;
    letter-spacing: 0.13px !important;
    cursor: pointer;
    font-family: Helvetica;
    font-size: 0.9375vw !important;
    font-weight: 700;
  }

  .breadcrumbs-link {
    cursor: pointer;
    font: normal normal bold Helvetica;
    letter-spacing: 0.13px !important;
  }

  .MuiTypography-colorPrimary {
    color: #000 !important;
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.2vw !important;
  }

  .workspace-name {
    margin-top: 0.25vw !important;
    margin-right: 1vw;
    font: normal normal 0.9375vw Helvetica;
  }
}
