.add-symbol-popup {
  // .modal-dialog{
  //   height: 25vw !important;
  //   overflow-y: scroll;
  //   overflow-x: hidden;
  // }
  .modal-content {
    background-color: #0c629b !important;
    color: white !important;
    .modal-body {
      background-color: #0c629b !important;
      text-align: inherit;
      display: flex;
      flex-direction: column;
      // height: 30vw;
      // overflow-y: scroll;s
      // overflow-x: hidden;
      // &::-webkit-scrollbar {
      //   width: 0.4vw;
      //   height: 0.4vw;
      // }
      // &::-webkit-scrollbar-track {
      //   background: #e8eaeb;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: #808080;
      //   border-radius: 20px;
      // }
    }
    .MuiButton-root {
      background-color: #fff !important;
      color: #0c629b !important;
      text-transform: none;
    }
    .modal-footer {
      border-top: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .label-text {
      color: white;
      font-size: 1vw;
      margin-left: 0;
      // margin-top: 1vw;
    }
    .add-line {
      cursor: pointer;
      font-size: 1vw;
      text-decoration: underline;
    }
    .MuiInputBase-root {
      background-color: #fff !important;
      width: 36.3vw !important;
      height: 3vw !important;
      border-radius: 0.1vw !important;
      color: black;
    }
    .input-field-symbol {
      color: black !important;
      height: 2vw !important;
      width: 38vw !important;
    }
    .symbol-field,
    .add-from-line,
    .add-to-line,
    .insulation-type,
    .fluid,
    .add {
      .MuiInputBase-root {
        width: 18vw !important;
        height: 2vw !important;
      }
    }
    // .insulation-type,
    .add-new {
      .MuiInputBase-root {
        background-color: #fff !important;
        width: 36vw !important;
        height: 2vw !important;
        border-radius: 0.1vw !important;
      }
    }
    .size-label,
    .size,
    .add,
    .jacketedLine,
    .agug,
    .spec-label,
    .spec,
    .label-hdr,
    .hdr-field,
    .holdLIne {
      display: flex;
    }
    .s2,.fluid {
      margin-left: 17.6vw;
    }
    .fluidlabel {
      margin-left: 14vw;
    }
    .assetCompType-label {
      margin-left: 16.3vw;
    }
    .seq-label {
      margin-left: 18vw;
    }
    .v2 {
      margin-left: 18.2vw;
    }
    .e2 {
      margin-left: 13.1vw;
    }
    .l2,
    .r2 {
      margin-left: 15.8vw;
    }
    .add-to-line {
      margin-left: 15.2vw;
    }
    .size2,.seq,.assetCompType,.fluid {
      margin-left: 2vw;
    }
    #to {
      margin-left: 2vw;
    }
    // .seq {
    //   margin-left: 6vw;
    // }
    // .newtoLine {
    //   margin-left: 21vw;
    // }
    .radio {
      display: block;
      .MuiTypography-body1 {
        color: #fff;
      }
    }
    .radio1 {
      margin-left: 14vw;
    }
    .radio2 {
      margin-left: 17.7vw;
    }
    .radio3 {
      margin-left: 17vw;
    }
    #spec,
    #seq,
    #hdr,
    #assetCompType,
    #size1,
    #size2,
    #insulation-type,
    #seat,
    #valve,
    #bonnet,
    #insulation-type,
    #fluid,
    #switch {
      // .MuiInputBase-input .MuiInput-input {
        padding-left: 0.3vw;
      // }
    }
  }
}

.fullmodal-add-symbol {
  // height:20vw !important;
  // overflow-x: hidden;
  // overflow-y: scroll;

  max-width: 40vw !important;
}
