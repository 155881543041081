.createWorkspace-container-PID {
  background: #f7f8fa;
  font-family: Helvetica;
  .footer-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  .MuiFormControl-marginDense {
    width: 45%;
    margin-left: 4% !important;
    margin-top: 0.5vw !important;
    margin-bottom: 0.2vw !important;
    height: 9vh !important;
  }
  
  .date {
    width: 21%;
  }


  .createWorkspace-form-wrapper-PID {
    display: flex;
    margin-top: 5vw;
    margin-left: 12.4vw;
    margin-bottom: 3vw;
    width: 75vw;
    background-color: #eff5f8;
    box-shadow: 0px 0.15vw 0.31vw #00000029;
    height: 64vh;
    form{
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }
    .createWorkspace-formblank-PID {
      width: 30%;
      height: 100%;
      background: #0c629b;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .create-text-PID {
      text-align: center;
      font: normal normal bold 1.5vw Helvetica;
      color: #ffffff;
      margin-top: 4vw;
    }
    .icon-workspace {
      height: 11.7vw;
      width: 11.7vw;
      position: absolute;
      top: 20vw;
      left: 18vw;
    }
  }

  .createWorkspace-wrapper {
    width: 70%;
    font-family: Helvetica;
    background-color: #eff5f8;
  }

  .createWorkspace-form-PID {
    padding: 3%;
    margin-right: 3%;
    label {
      color: #0c629b;
    }
    .MuiFormLabel-root {
      font-size: 0.9vw;
    }
    .MuiInputBase-root {
      font-size: 1vw !important;
    }
    input[type='date'] {
      text-transform: uppercase;
      font-size: 0.9vw;
    }
    .add-users-icon {
      margin-top: 2%;
      cursor: pointer;
      .add-icon {
        width: 3vw;
        height: 4vh;
      }
    }
    .add-users-label {
      margin-left: 1.5vw;
      position: absolute;
      margin-top: 2vw;
    }
    .add-users-field-PID {
      display: flex;
      .names-dropdown {
        width: 23vw;
        height: 4vw;
        margin-left: 2vw;
      }
      .names {
        width: 25vw;
        overflow: hidden;
      }
      .assign-role-dropdown {
        width: 27.5vw;
        margin-left: 2vw !important;
      }
    }
  }
}


.Link {
  span {
    cursor: pointer;
    text-decoration: underline;
    color: #0c629b;
    margin-left: 4.1%;
    font: normal normal bold 1.04vw Helvetica;
    line-height: 2;
  }
}

.next-btn {
  background: #0c629b;
  border: 1px solid #0c629b;
  border-radius: 4px;
  color: #ffffff;
  width: 6em;
  height: 2em;
}

p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.btn-fields {
  margin-left: 1vw !important;
  margin-top: 1.5vw !important;
}

.btn-fields .save-btn {
  font: normal normal 1.3vw Helvetica;
  background: #0c629b;
  border: 1px solid #0c629b;
  border-radius: 4px;
  color: #ffffff;
  width: 8vw;
  height: 5.5vh;
  margin-right: 2vw;
}

.btn-fields .cancel-btn {
  font: normal normal 1.3vw Helvetica;
  background: #ffffff;
  border: 1px solid #4b4a55;
  border-radius: 4px;
  width: 8vw;
  height: 5.5vh;
}

.userNameAndConfig {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .MuiFormLabel-root {
    padding-top: 1vw;
  }
 .MuiFormControl-marginDense {
  margin-left: 4vw !important; 
   margin-top: -2vw !important;
   padding-top: 1.4vw;
 }
}