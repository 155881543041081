.MuiLinearProgress-barColorPrimary {
    background-color: #74C323;
}

.MuiLinearProgress-root {
    height: 0.3vw;
}

.log-files {
    position: absolute;
    margin-top: -1.5vw;
    margin-left: 43vw;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    .log-icon {
        width: 1.3vw;
        margin-right: 0.5vw;
    }
    .log-text {
        font-size: 1vw;
        margin-left: 0.5vw;
    }
}