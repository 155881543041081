.project-setup-container {
    background: #F7F8FA;
    font-family: Helvetica;
}

.project-setup-form-wrapper {
    display: flex;
    margin-top: 3em;
    margin-left: 6em;
    margin-bottom: 3em;
    width: 67em;
    height: 34em;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}
    
.project-setup-user-formblank {
    width: 30%;
    background: #0C629B;
    .icon-workspace {
      height: 8em;
      margin-top: 2em;
      margin-left: 6em;
    }
}
    
.project-setup-user-form {
    width: 70%;
    padding: 1%;
    .no-field-wrapper {
        display: flex;
        .line-no-field {
            display: flex;
            width: 100%;
        }
        .doc-no-field {
            display: flex;
            width: 100%;
        }
        .add-users-icon {
          margin-top: 3%;
        }
        .MuiFormControl-marginDense {
            margin-left: 2%;
            margin-bottom: 3%;
            width: 100%;
        }
    }
    .MuiFormControl-marginDense {
        margin-left: 5%;
        margin-bottom: 3%;
        width: 45%;
    }
}

.user-text {
    text-align: center;
    font: normal normal bold 20px Helvetica;
    color: #FFFFFF;
    margin-top: 3em;
}

.select-dropdown-container {
    display: flex;
    margin-top: 5%;
    font-family:  Helvetica;
    .MuiInput-root {
        width: 100%;
    }
    .line-no-select-dropdown-wrapper {
        width: 45%;
        margin-left: 2%;
    }
    .doc-no-select-dropdown-wrapper {
        width: 45%;
        margin-left: 5%;
    }
}

.project-setup-fields {
    margin-top: 3%;
    margin-left: 5%;
    font: normal normal bold 16px Helvetica;
}

.btn-fields {
    margin-left: 5%;
    .save-btn {
        font: normal normal 15px Helvetica;
        background: #0C629B;
        border: 1px solid #0C629B;
        border-radius: 4px;
        color: #FFFFFF;
        width: 6em;
        height: 2em;
        margin-right: 2%;
    }
    .cancel-btn {
        font: normal normal 15px Helvetica;
        background: #FFFFFF;
        border: 1px solid #4B4A55;
        border-radius: 4px;
        width: 6em;
        height: 2em;
    }
}
