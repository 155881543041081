.image-container {
    width: 50vw !important;
    height: 80vh !important;
    padding: 0.5vw !important;
    padding-left: 1.5vw !important;
    display: flex;
    .PID-wrapper {
        display: block;
    }
    ._loading_overlay_overlay {
        background-color: transparent;
    }

    .image-toolbar {
        margin-top: 10vh;
        margin-right: 0.5vw;

        .tool {
            margin-top: 2vh;

            &:hover {
                opacity: 0.5;
                cursor: pointer;
            }
        }
    }

    .image-options {
        display: flex;

        p {
            text-align: left;
            letter-spacing: 0.2px;
            color: #000000;
            opacity: 1;
            font-size: 0.7vw;
            width: 50%;
            margin-left: -2vw;
        }

        .text {
            color: #0C629B;
            margin-left: 1vw;
            font: normal normal normal 1vw Helvetica;
        }

        .expand-PID {
            margin-left: 5vw;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        .print-PID {
            margin-left: 2vw;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}
.css-df17o1 {
    width: 100vw !important;
}