@import '../../../GlobalVriables.scss';

.workspace-container-PID {
    font-family: Helvetica !important;
    overflow-x: hidden;
    height: 100vh;
    .footer {
        top: 100vh;
    }
    .workspace-wrapper-PID {
        background: #F7F8FA;
    }
    ol {
        li{
            font-family: Helvetica !important; 
        }
    }
    ._loading_overlay_overlay {
        height: 100vh;
        width: 100vw;
    }
}

p, li, div, nav, span,td, * {
    font-family: Helvetica !important;
}

.icon-add-PID{
    margin-right: 0.5vw;
    width: 1.5vw;
}

.projLink {
    cursor: pointer;
    color: #0C629B;
}

.worspace-titlebar-PID {
    display: flex;
    padding: 1.5vw;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    font-family: Helvetica;
    .workspace-title-PID {
        font-size: 1.145833333vw;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .create-new-workspace-wrapper-PID {
        .create-new-workspace-btn-PID {
            background: $primary-color;
            border-radius: 0.4vw;
            text-align: center;
            font-family: $font-family;
            font-size: 1.2vw;
            letter-spacing: 0px;
            color: $background-color;
            width: 16.7vw;
            padding: 0.6vw;
            cursor: pointer;
        }
        .icon-add {
            margin-right: 3%;
        }
    }
}

.exportReport {
    margin-left: 1.5vw;
    margin-bottom: 1vw;
    margin-top: 1vw;
    .btn.focus, .btn:focus {
        box-shadow: 0 0 0 0 transparent;
    }
    .preview-text {
        color: #0C629B;
        border: 0;
        font-size: 1vw;
        border-radius: 0;
        margin-left: -1vw;
        &:hover {
            opacity: 0.6;
        }
    }
    .toolbar-icon {
        height: 1vw;
        margin-right: 0.5vw;
        &:hover {
            opacity: 0.6;
        }
    }
}

.workspace-data-table-wrapper-PID {
    .react-bootstrap-table {
        width: 100%;
        .table {
            // height: 58vh;
            background-color: $background-color;
            width: 100%;
            thead {
                background-color: #E8EAEB;
                text-align: center;
                height: 4vw !important;
                border-top: 0.3vw solid #0c629b;
                .selection-cell-header {
                    padding-left: 1.5vw;
                }
                th {
                    font-size: 1vw;
                    text-align: center;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                    // padding: 0 !important;
                }
            }
            tbody {
                text-align: center;
                font: normal normal normal 0.729vw Helvetica;
                letter-spacing: 0.27px;
            }
            .selection-cell {
                padding-left: 1.5vw;
            }
            th:last-child {
                // padding-right: 2vw;
                padding: 0;
            }
            tr {
                height: 3vw !important;
            }
            td {
                vertical-align: middle;
                font-size: 1vw;
                text-align: center;
                vertical-align: middle;
                border-left: 0px;
                border-right: 0px;
                word-wrap: break-word;
                // padding: 0 !important;
            } 
            td:last-child {
                padding-right: 3vw;
                padding-left: 0;
            }
            input[type='checkbox'] {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 0;
                width: 1vw !important;
                height: 1vw !important;
                font-size: 1vw !important;
            }
        }
    }
    // .page-item.active .page-link {
    //     z-index: 0;
    //     background-color: #6c757d;
    //     border-color: #6c757d;
    //     width: 2.6vw;
    //     height: 3vw;
    //     font-size: 1.3vw;
    // }
    // input[type=checkbox], input[type=radio] {
    //     height: 1.2vw !important;
    // }
    // .btn-secondary {
    //     width: 5vw;
    //     height: 3vw;
    //     font-size: 1.3vw;
    // }
    .react-bootstrap-table-pagination {
        padding: 0;
        width: 100%;
        #pageDropDown {
            font-size: 1vw;
            background-color: #53565A;
            border-color: #53565A;
        }
        .dropdown-menu {
            font-size: 1vw;
            min-width: 2.5vw;
            margin-top: -12vw;
        }
    }
}