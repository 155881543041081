.createWorkspace-container-PID {
  background: #f7f8fa;
  font-family: Helvetica;
  .footer-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.MuiFormControl-marginDense {
  width: 45%;
  margin-left: 3% !important;
  margin-top: 0.5vw !important;
  margin-bottom: 0.2vw !important;
  height: 9vh !important;
}

.date {
  width: 21%;
}

.createWorkspace-wrapper-PID {
  height: 70vh;
}

.createWorkspace-form-wrapper-PID {
  display: flex;
  margin-top: 5vw;
  height: 60vh;
  background: #ffffff;
  box-shadow: 0px 0.156vw 0.3125vw #00000029;
  width: 75vw;
  margin-left: 12.4vw;

}

form {
  //overflow-y: scroll;
  overflow-x: hidden;
  .makeStyles-formControl-1 {
    margin-top: 0;
  }
}

.createWorkspace-formblank-PID {
    width: 22.75vw;
    background: #0c629b;
    display: flex;
    flex-direction: column;
    justify-content: center;
  .icon-workspace {
    height: 11.7vw;
    width: 11.7vw;
    position: absolute;
    top: 18vw;
    left: 10vw;
  }
}

.Detection-form-PID {
  margin-top: 2vw;
  padding-left: 2.5vw;
  padding-right: 2vw;
  .add-users-icon {
    margin-top: 2%;
    cursor: pointer;
    .add-icon {
      width: 3vw;
      height: 4vh;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .MuiSvgIcon-root {
    width: 1.5vw;
    height: 1.5vw;
  }
  .add-users-label {
    margin-left: 2.5vw;
    position: absolute;
    margin-top: 2vw;
  }
  .add-users-field-PID {
    margin-top: 1.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .names-dropdown {
      width: 23vw;
      height: 4vw;
      margin-left: 2vw;
    }
    .names {
      width: 25vw;
      overflow: hidden;
    }
  }

  .add-users-field-PID1 {
    display: flex;
    .names-dropdown {
      width: 23vw;
      height: 4vw;
      margin-left: 10vw;
    }
    .names {
      width: 25vw;
      overflow: hidden;
    }
  }
  .MuiFormLabel-root {
    font-size: 1.3vw;
  }
  .MuiInputBase-root {
    font-size: 1.3vw !important;
  }
}

.create-text-SDL {
  text-align: center;
  font: normal normal bold 1.5vw Helvetica;
  color: #FFFFFF;
  margin-top: -19.5vw;
}


.next-btn {
  background: #0c629b;
  border: 0.05vw solid #0c629b;
  border-radius: 0.208vw;
  color: #ffffff;
  width: 0.019vw;
  height: 0.0065vw;
}

.MuiSelect-icon {
  color: #0c629b !important;
}

.back,
.add-users-icon {
  &:hover {
    opacity: 0.8;
  }
}

.back {
  .preview-text {
    margin-top: 3%;
    padding-right: 0;
  }
}

p {
  display: block;
  margin-top: 0.003vw;
  margin-bottom: 0.003vw;
  margin-left: 0;
  margin-right: 0;
}

.btn-fields {
  display: flex;
  justify-content: center;
}

.btn-fields .save-btn {
  font: normal normal 0.9vw Helvetica;
  background: #0c629b;
  border-radius: 0.208vw;
  width: 8.4vw;
  height: 2.29vw;
  padding: 0.156vw 0;
  color: #ffffff;
}

.btn-fields .cancel-btn {
  font: normal normal 0.9vw Helvetica;
  background: #ffffff;
  border: 0.05vw solid #0c629b;
  border-radius: 0.208vw;
  width: 8.4vw;
  height: 2.29vw;
  padding: 0.156vw 0;
  color: #0c629b;
}

.addUserTable td {
  text-align: left;
}
.setDetection {
  overflow: hidden;
  background-color: #eff5f8;
  width: 53vw;
  .selectBox {
    .MuiMenuItem-root {
      font-size: 1vw !important;
      padding: 0.208vw 0.625vw;
    }
    .MuiSelect-select,
    .selectBoxLi {
      font-size: 1vw;
    }
  }
  .MuiPaper-root {
    margin-top: 2.6vw;
  }
}
.selectBoxLi {
  font-size: 1vw !important;
}





.add-users-field-PID .selectBox {
     .makeStyles-formControl-4 {
      width:18.1vw;
      max-width: 18.1vw;
      min-width: 18.1vw;
    }
  }

  .preview-data {
    min-height: 19vh;
    max-height: 19vh;
    display: block;
    width: 46.5vw;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.8vw;         
      height: 0.8vw;     
  }
  &::-webkit-scrollbar-track {
      background: #E8EAEB;        
  }
    
  &::-webkit-scrollbar-thumb {
      background-color: #808080;    
      border-radius: 20px;       
  }
  }
  .selectedListOfOption{
    margin: 4px !important;
  }

  .sdlSelectAttribut {
    .modal-dialog{
      width: 30vw;
    }
    .modal-content {
      border-top: none !important;
      .modal-header {
        padding: 0.5vw 1vw;
        display: block;
        .h4 {
          font-size: 1.25vw;
          color: #fff;
          display: flex;
          justify-content: space-between;
          button {
            padding: 0;
            font-size: 0.1vw;
            span {
              display: block;
              .MuiSvgIcon-root {
                width: 0.7em;
              }
            }
          }
        }
        background: #0C629B;
        color: #FFFFFF;
        button {
          
          span {
            color: #FFF;
            opacity: 1;
            display: none;
          }
          opacity: 1;
        }
      }
      .modal-body {
        border-radius: 5px;
        display: flex;
        justify-content: center;
      }
    }
  }
  
  

  