.image-container {
    width: 50vw;
    height: 80vh;
    padding: 0.5vw;
    padding-left: 1vw;
    display: flex;

    .image-toolbar {
        margin-top: 10vh;
        margin-right: 0.5vw;

        .tool {
            margin-top: 2vh;

            &:hover {
                opacity: 0.5;
                cursor: pointer;
            }
        }
    }

    .image-options {
        display: flex;

        p {
            text-align: left;
            letter-spacing: 0.2px;
            color: #000000;
            opacity: 1;
            font-size: 0.7vw;
            width: 50%;
            margin-left: -2vw;
        }

        .text {
            color: #0C629B;
            margin-left: 1vw;
            font: normal normal normal 1vw Helvetica;
        }

        .expand-PID {
            margin-left: 5vw;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        .print-PID {
            margin-left: 2vw;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}

.expand-window-canvas-PID {
    // .modal.show .modal-dialog {
    //     margin-left: 2vw;
    // }
    // margin-left: 3vw;
    padding-right: 16vw !important;
    .modal-content {
        border-top: none !important;
        min-width: 86vw !important;
        max-width: 86vw !important;
        // margin-left: -1vw !important;
    }

    .modal-header {
        background-color: #0C629B;
        height: 3vw;

        .close-icon {
            cursor: pointer;
            color: white;
            // border: 2px solid red;
            // background: red;
            // border-radius: 2.5vw;
            height: 1.5vw;
            width: 1.5vw;
            margin-left: 82vw;
            margin-top: -0.3vw;
        }
    }

    .image-container {
        // width: 100%;
        padding: 0.5vw;

        // height: auto;
        // overflow-y: scroll;
        // canvas {
        //     width: 84vw !important;
        //     height: auto !important;
        // }
        .image-options {
            display: none;
        }
    }
}


