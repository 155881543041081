@import "../../../../GlobalVriables.scss";
.search-button {
  background-color: #0c629b;
  border-radius: 100%;
  margin-left: -10vw;
  height: 2.7vw;
  width: 2.8vw;
  border: transparent;
}

.search-icon {
  height: 1vw;
  width: auto;
  margin-bottom: 0.2vw;
}

.process-page-loader {
  ._loading_overlay_overlay {
    height: 38vw;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #7cfc00;
  border-radius: 50%;
  display: inline-flex;
  position: relative;
}

.dot1 {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-flex;
  position: relative;
}

.StampGrid {
  .reports-download-icon {
    display: inline;
  }
}

.searchbar-modal {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 12px #00000017;
  padding: 0.3vw 1.5px;
  justify-content: space-between;
  align-items: center;
  .MuiFormControl-root {
    margin-left: 1vw;
    width: 13vw;
    min-width: 13vw;
  }
  .MuiInputBase-input {
    font-size: 0.8vw;
    height: 0.8em;
    box-shadow: 0px 2px 10px #0000000f;
    border: 1px solid #cccccc;
    border-radius: 0.3vw;
    padding: 0;
    display: flex;
    height: 2vw;
    flex-direction: row;
    align-items: center;
    padding-left: 0.5vw;
  }
  .MuiMenuItem-root {
    font-size: 1vw;
  }
  .MuiOutlinedInput-input {
    padding: 8px;
  }
  .MuiSelect-icon {
    color: #0c629b;
  }
  .MuiSelect-select.MuiSelect-select {
    font-size: 1vw;
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiSvgIcon-root {
    height: unset;
    width: 1.5vw;
    top: unset;
  }
  .MuiMenu-list {
    .MuiMenuItem-root {
      font-size: 1vw !important;
    }
  }
  .DayPickerInput {
    input {
      width: 7vw;
      font-size: 1vw;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0.2vw 0.3vw #0000000f;
      border: 1px solid #cccccc;
      border-radius: 0.2vw;
      text-align: center;
    }
  }

  .ant-picker-input > input {
    font-size: 0.9vw !important;
  }
  .ant-picker-header-view button,
  .ant-picker-date-panel .ant-picker-content th,
  .ant-picker-cell .ant-picker-cell-inner {
    font-size: 0.9vw !important;
  }
  .ant-picker-content th,
  .ant-picker-content td {
    font-size: 0.9vw !important;
  }
}

.search-text-bar {
  height: 2.7vw;
  width: 22vw;
  background: #ffffff;
  border-radius: 2vw;
  box-shadow: 0px 2px 10px #0000000f;
  border: 1px solid #cccccc;
  padding-left: 1%;
  .input::placeholder {
    font-size: 1vw;
  }
}

.search-text-bar:focus-visible {
  outline: 0;
}

.search-text-bar:focus {
  border: transparent;
}

.date-picker-wrapper {
  margin-left: 15%;
  display: flex;
}

.label-text {
  font: normal normal normal 1vw Helvetica;
  letter-spacing: 0px;
  color: #191a1e;
  margin-left: -4vw;
  margin-right: -7vw;
}

.grid-toolbar-wrapper {
  padding: 0.5vw;
  margin-left: 1vw;
}

.preview-text {
  padding-left: 1vw;
  padding-right: 3vw;
  font: normal normal normal 1vw Helvetica;
  color: #0c629b;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.upload_text {
  font: normal normal normal 0.8vw Helvetica;
  color: #0c629b;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.toolbar-icon {
  cursor: pointer;
  height: 1vw;
  &:hover {
    opacity: 0.6;
  }
}

.batchStatusList,
.mainTable {
  .MuiDialog-paperFullWidth {
    width: 100%;
    max-width: 95vw;
    .MuiDialogContent-root {
      width: 100%;
      margin: 11px 0;
    }
    .MuiDialogTitle-root {
      padding: 0.78125vw;
      h2 {
        font-size: 1.25vw;
        color: #fff;
      }
    }
    .MuiTableCell-head {
      line-height: 1vw;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    table {
      width: 100%;
      border: 1px solid;

      tr {
        border-bottom: 1px solid #707070;
      }

      th {
        text-align: left;
        padding: 0.390625vw;
        border: 1px solid #fff;
        font-size: 0.8vw;
        background-color: #707070;
        color: #fff;
        font-weight: 700;
      }
      td {
        width: 30%;
        text-align: left;
        padding: 0.390625vw;
        border: 1px solid #fff;
        font-size: 0.8vw;
      }

      th:first-child,
      td:first-child {
        width: 2%;
        text-align: left;
      }
      th:nth-child(2),
      td:nth-child(2) {
        width: 15%;
        text-align: left;
      }
      th:nth-child(3),
      td:nth-child(3) {
        width: 6%;
        text-align: left;
      }
      th:nth-child(4),
      td:nth-child(4) {
        width: 9%;
        text-align: left;
      }
      th:nth-child(5),
      td:nth-child(5) {
        width: 10%;
        text-align: left;
      }
      th:nth-child(6),
      td:nth-child(6) {
        width: 9%;
        text-align: left;
      }
      th:nth-child(7),
      td:nth-child(7) {
        width: 9%;
        text-align: left;
      }
      th:nth-child(8),
      td:nth-child(8) {
        width: 11%;
        text-align: left;
      }
      th:nth-child(9),
      td:nth-child(9) {
        width: 10%;
        text-align: left;
      }
      th:nth-child(10),
      td:nth-child(10) {
        width: 9%;
        text-align: left;
      }
    }

    .subTable {
      width: 80%;
      margin: 0 auto;
      th:nth-child(1),
      td:nth-child(1) {
        width: 20%;
        text-align: left;
      }
      th:nth-child(2),
      td:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5) {
        width: 15%;
        text-align: left;
      }
    }
  }
}

.linenumber {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fileUploadFaile {
  width: 100vw;
  .table {
    background-color: $background-color;
    thead {
      background-color: #e8eaeb;
      text-align: center;
      height: 4vw !important;
      border-top: 0.3vw solid #0c629b;
      th {
        font-size: 1vw;
        text-align: left;
        padding-left: 4vw;
        vertical-align: middle;
        border-left: 0px;
        border-right: 0px;
      }
    }
    tbody {
      text-align: center;
      font: normal normal normal 0.729vw Helvetica;
      letter-spacing: 0.27px;
    }
    tr {
      height: 3vw !important;
    }
    td {
      vertical-align: middle;
      font-size: 1vw;
      text-align: left;
      padding-left: 4vw;
      vertical-align: middle;
      border-left: 0px;
      border-right: 0px;
    }
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $primary-color !important;
}

.processgrid-container {
  font-family: Helvetica !important;
  .table-pid {
    width: 100%;
    height: 43vh;
    display: inline-block;
    .resize-div {
      resize: horizontal;
      overflow: auto;
      min-width: 9vw;
      font-weight: 500;
      text-align: left;
      padding-left: 0.5vw;
    }
    tr {
      td:first-child,
      th:first-child {
        padding-left: 1vw;
      }
    }
    tbody {
      margin-top: 5vw;
      tr:nth-child(even) {
        background-color: #f0f8ff;
      }
      tr {
        height: 3vw;
        &:hover {
          background-color: rgb(248, 245, 245);
        }
      }
    }

    .table-pid th {
      position: relative;
    }
    .table-head-pid {
      text-align: center;
      color: #0c629b;
      border-bottom: 1px solid #ddd;
      position: sticky;
      top: 0;
      z-index: 2;

      .table-filter {
        font-size: 1vw;
        background-color: #fff;
        .table-filter-icon {
          margin-right: 1vw;
        }
      }
      .checkbox-pid {
        border-radius: 0.5vw;
        background: #ffffff;
        width: 1vw;
        height: 0.5vw;
        margin-left: -0.45vw;
      }
    }
    .cell {
      font-size: 0.9vw !important;
      text-align: center;
      vertical-align: middle;
      letter-spacing: 0.27px;
      border-bottom: 1px solid #ddd;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 10vw;
      text-align: left;
      padding-left: 0.5vw;
    }
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.8vw;
      height: 0.8vw;
    }
    &::-webkit-scrollbar-track {
      background: #e8eaeb;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #808080;
      border-radius: 20px;
    }
  }
  .pager-style-pid {
    margin-left: 10vw;
  }
}

.pagerFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p,
  label {
    font-size: 1vw !important;
  }

  .pagnaitioncont {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    select {
      margin-bottom: 0;
      margin-right: 2vw;
      height: 2.6vw;
      border-radius: 6px;
      border-color: #dee2e6;
      color: #53565a;
    }
    nav {
      margin-right: 1.5vw;
    }
  }

  .countdisplay {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.superseded-div {
  .superseded-radio {
    display: block;
    .MuiTypography-body1 {
      font-size: 1vw;
      margin-left: -0.5vw;
    }
    .MuiSvgIcon-root {
      height: 0.75em;
      width: 0.75em;
    }

    .MuiFormControlLabel-root {
      margin-left: -1vw;
      margin-right: 1vw;
    }
  }
}
.processgrid-container {
  .editConfigTable {
    background-color: #e8eaeb;
    text-align: center;
    height: 4vw !important;
    border-top: 0.3vw solid #0c629b;

    tr {
      background-color: #e8eaeb !important;
      color: #0c629b !important;
      .resize-div {
        font-weight: 700;
      }
    }
  }
}
