.workspace-wrapper{
    .workspace-titlebar {
        .workspace-title{
            .Link {
                
            }
        }
    }
}

#proposal-no-label {
    // .MuiFormLabel-root .Mui-disabled {
        color : #0C629B;
    // }
}

#proposal-no {
    color: black;
}