.options-bar-wrapper {
  border-bottom: 0.1vw solid #bfbcbc;
  height: auto;
  align-items: center;
  .PrivateSwitchBase-root-8,
  .PrivateSwitchBase-root-14,
  .MuiIconButton-colorPrimary {
    padding: 0.6vw;
    margin-left: 1vw;
    margin-top: 0.5vw;
  }
  .selectall-checkbox .MuiSvgIcon-root {
    width: 2vw !important;
    height: 4vh !important;
    margin-left: 0.4vw;
  }
}

.delete {
  display: inline;
  margin-left: 2vw;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.selectAll {
  font: normal normal normal 1.3vw Helvetica;
  margin-left: -0.5vw;
  &:hover {
    opacity: 0.6;
  }
}

.tag-files {
  display: inline-flex;
  margin-left: 6vw;
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiSelect-select.MuiSelect-select {
    width: 9vw;
    color: #0c629b;
    font: normal normal normal 1.3vw Helvetica !important;
    background: transparent;
  }
  .MuiSelect-icon {
    color: #0c629b;
  }
  &:hover {
    opacity: 0.6;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 0px;
  }
}

.tagged-icon {
  margin-left: 2%;
  height: 20px;
}

.upload {
  display: inline;
  margin-left: 29vw;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.file-checkbox {
  background: #ffffff;
  border: 0.1vw solid #646464;
  border-radius: 0.1vw;
  width: 2vw;
  height: 1.15vw;
  margin-right: 0.5vw;
}

.file-text {
  margin-top: -0.5vh;
  margin-bottom: 0.5vh;
}

.file {
  margin-bottom: 1%;
}

.batch-process-modal {
  .modal-dialog {
    max-width: 40vw;
  }
  .modal-footer {
    justify-content: center;
  }
  .MuiButton-root {
    margin-left: 0;
  }
  .MuiButton-label{
    text-transform: capitalize !important;
  }
}

.time {
  width: 65%;
  margin-left: 2vw !important;
}
