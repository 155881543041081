.basic-table {
  position: relative;
  margin: auto;
  border: 1px solid black;
  border-collapse: collapse;
  width: 50%;

  .cell {
    position: relative;
    border: 1px solid black;
    padding: 3px;
  }
}

.preview-text {
  font: normal normal normal 1vw Helvetica;
}
