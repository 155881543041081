@import '../../../GlobalVriables.scss';

.workspace-container {
    font-family: Helvetica !important;
    overflow-x: hidden;
    height: 100vh;
    .footer {
        top: 100vh;
    }
    .workspace-wrapper {
        background: #F7F8FA;
    }
    ol {
        li{
            font-family: Helvetica !important; 
        }
    }
    ._loading_overlay_overlay {
        height: 100vh;
        width: 100vw;
    }
}
p, li, div, nav, span,td, * {
    font-family: Helvetica !important;
}
.worspace-titlebar {
    display: flex;
    padding: 1.5vw;
    flex-direction: row;
    justify-content: space-between;
    font-family: Helvetica;
    .workspace-title {
        font-size: 1.145833333vw;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .create-new-workspace-wrapper {
        .create-new-workspace-btn {
            background: $primary-color;
            border-radius: 0.4vw;
            text-align: center;
            font-family: $font-family;
            font-size: 1.2vw;
            letter-spacing: 0px;
            color: $background-color;
            width: 16.7vw;
            padding: 0.6vw;
            cursor: pointer;
            .icon-add {
                margin-right: 3%;
            }
        }
    }
}

.filter-icon {
    height: 1vw;
    width: 1vw;
}

.usersList {
    color: $primary-color;
    cursor: pointer;
}

.upload-files {
    input[type="file"] {
        display: none;
    }
}

.custom-file-upload {
    background: transparent;
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 0;
}
.workspace-container {
    .workspace-data-table-wrapper {
        .react-bootstrap-table {
            width: 100vw;
            .table {
                background-color: $background-color;
                thead {
                    background-color: #E8EAEB;
                    text-align: center;
                    height: 4vw !important;
                    border-top: 0.3vw solid #0c629b;
                    th {
                        font-size: 1vw;
                        text-align: left;
                        padding-left: 4vw;
                        vertical-align: middle;
                        border-left: 0px;
                        border-right: 0px;
                    }
                    th:nth-child(4n) {
                        padding-left: 2vw; 
                        width: 10vw;
                    }
                    th:nth-child(5n), th:nth-child(6n) {
                        padding-left: 1vw; 
                        padding-left: 1vw;
                    }
                }
                tbody {
                    text-align: center;
                    font: normal normal normal 0.729vw Helvetica;
                    letter-spacing: 0.27px;
                }
                tr {
                    height: 3vw !important;
                }
                td {
                    vertical-align: middle;
                    font-size: 1vw;
                    text-align: left;
                    padding-left: 4vw;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                }
                td:nth-child(4n){
                    padding-left: 2vw; 
                }
                 td:nth-child(5n), td:nth-child(6n) {
                    padding-left: 1vw; 
                }
                td:nth-child(3){
                    word-break: break-all; 
                }
            }
        }
    }
}


.reports-wrapper {
    display: inline-flex;
    .preview-text
    {
        display: flex;

    }    
}

.MuiDialogTitle-root {
    background: #0C629B;
    color: #FFFFFF;
}

.MuiDialogContent-root {
    overflow-x: hidden;
    width: 100%;
    &::-webkit-scrollbar {
        width: 0.8vw;         
        height: 0.8vw;     
    }
    &::-webkit-scrollbar-track {
        background: #E8EAEB;        
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: #808080;    
        border-radius: 20px;       
    }
}

.MuiButton-root {
    background: #0C629B;
}

.MuiButton-root:hover {
    background: #0C629B;
}

.MuiDialogActions-root {
    margin-right: 5%;
    margin-bottom: 2%;
}

.usersList {
    overflow: hidden;
    text-overflow: ellipsis;
}

.userListDialog {
    .MuiDialog-paperFullWidth {
        width: 50%;
        .MuiDialogContent-root{
            width: 100%;
            margin: 11px 0;
        }
        .MuiDialogTitle-root {
            padding: 0;
        }
        h2 {
            padding: 10px;
            font-size: 1.25vw;
        }
        table {
            width: 100%;
            border: 1px solid;
            th, td {
                width: 30%;
                text-align: left;
                padding: 0.390625vw;
                border: 1px solid;
                font-size: 1.09375vw;
            }
            th:first-child, td:first-child{
                width: 70%;
                text-align: left;
            }
        }
    }
}
.users-dialog {
    .MuiDialog-paperScrollPaper {overflow-x: hidden;}
}

.page-item.active .page-link {
    background-color: #53565A;
    border-color: #53565A;
}

.page-link {
    color: #53565A;
    font-size: 1vw;
}

.react-bootstrap-table-pagination {
    padding: 0 1.5vw;
    #pageDropDown {
        font-size: 1vw;
        background-color: #53565A;
        border-color: #53565A;
    }
    .dropdown-menu {
        font-size: 1vw;
        min-width: 2.5vw;
    }
}

.delete-confirm {
    .MuiDialog-paper {
        width: 20vw;
        height: auto;
        background-color: #f7f8fa;
    }

    .MuiDialogTitle-root {
        padding: 5px;
    }
    .MuiDialogContent-root {
        display: flex;
    }
}

.table1 {
    border: 0 !important;
    td {
        border: 0 !important;
    }
    thead {
        background-color: $border-color;
        color: $background-color;
    }
    tbody {
        tr:nth-child(even) {
            background-color: #E8EAEB;
        }
    }
}