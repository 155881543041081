@import '../../GlobalVriables.scss';

.footer-wrapper {
    text-align: center;
    background: #53565A 0% 0% no-repeat padding-box;
    color: $background-color;
    font: normal normal normal $font-size-18px $font-family;
    letter-spacing: 0px;
    p {
        padding: 1vw 0;
        margin: 0;
    }
}

