/* Application styles */
.app {
    background-color: #fff;
    padding: 0;
    // min-width: 375px;
  }
  
  // .main--home {
  //   // min-height: 27.2vw;
  // }
  .main--details {
    min-height: 40em;
  }
  
  