@import '../../../GlobalVriables.scss';

.editWorkspace-container {
    background: #F7F8FA;
    font-family: Helvetica;
    .MuiFormControl-marginDense {
      width: 45%;
      margin-left: 4% !important;
      margin-top: 0.5vw !important;
      margin-bottom: 0.2vw !important;
      height: 9vh !important;
    }
    .date {
        width: 21%;
    }
}

.editWorkspace-form-wrapper {
  display: flex;
  margin-top: 4vw;
  margin-left: 12.4vw;
  margin-bottom: 3vw;
  width: 75vw;
  background-color: #eff5f8;
  box-shadow: 0px 0.15vw 0.31vw #00000029;
  height: 61.5vh;  
  form {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
}
    
  .editWorkspace-formblank {
    width: 30%;
    background: #0c629b;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .icon-workspace {
      height: 11.7vw;
      width: 11.7vw;
      position: absolute;
      top: 20vw;
      left: 18vw;
    }
  }
  
  .editWorkspaceform-wrapper {
    width: 70%;
    font-family: Helvetica;
    background-color: #eff5f8;
    .editWorkspace-form {
      padding: 3%;
      margin-right: 3%;  
      label {
        color: #0c629b;
      }
      .MuiInputBase-root {
        font-size: 1vw;
      }
      input[type='date'] {
        text-transform: uppercase;
        font-size: 0.9vw;
      }
      .MuiFormLabel-root {
        font-size: 1vw;
      }
      .add-users-icon {
        margin-top: 2%;
        cursor: pointer;
        .add-icon {
          width: 3vw;
          height: 4vh;
        }
      }
      .add-users-label {
        margin-left: 2.5vw;
        position: absolute;
        margin-top: 2vw;
      }
      .add-users-field-PID {
          display: flex;    
          .names-dropdown {
            width: 23vw;
            height: 4vw;
            margin-left: 2vw;
          }
          .names {
            width: 25vw;
            overflow: hidden;
          }   
          .assign-role-dropdown {
            width: 26vw;
            margin-left: 1.3vw !important;
          } 
      }

      .scrollit {
        overflow:scroll;
        height:100px;
      }
      .add-users-field {
          display: flex;
      }
    }
  }

  .view-users {
    cursor: pointer;
    text-decoration: underline;
    color: #0c629b;
    margin-left: 4%;
    font: normal normal bold 1.04vw Helvetica;
    line-height: 2;
  }
    
  .create-text {
    text-align: center;
    font: normal normal bold 1.5vw Helvetica;
    color: #FFFFFF;
    margin-top: 4vw;
  }
  
  .next-btn {
    background: #0C629B;
    border: 1px solid #0C629B;
    border-radius: 4px;
    color: #FFFFFF;
    width: 6em;
    height: 2em;
  }
    
  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  .btn-fields {
    margin-left: 1vw !important;
    margin-top: 1.5vw !important;
  }

  .btn-fields .save-btn {
    font: normal normal 1.3vw Helvetica;
    background: #0C629B;
    border: 1px solid #0C629B;
    border-radius: 4px;
    color: #FFFFFF;
    width: 8vw;
    height: 5.5vh;
    margin-right: 2vw;
}

.btn-fields .cancel-btn {
  font: normal normal 1.3vw Helvetica;
  background: #FFFFFF;
  border: 1px solid #4B4A55;
  border-radius: 4px;
  width: 8vw;
  height: 5.5vh;
}