.create-workspace-container {
  background: #f7f8fa;
  font-family: Helvetica;
  height: 100vh;
  .footer-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  ._loading_overlay_overlay {
    height: 100vh;
  }
}

.visually-hidden {
  position: relative !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.user-form-wrapper {
  display: flex;
  margin-top: 5vw;
  margin-left: 12.4vw;
  margin-bottom: 3vw;
  width: 75vw;
  background-color: #eff5f8;
  box-shadow: 0px 0.15vw 0.31vw #00000029;
  height: 60vh;
  .MuiFormControl-marginDense{
    margin-top : -1.3vw !important;
  }
}

.modelNameCV {
  #modelNameCV-label {
    margin-left: 1.5vw;
    width:14vw;
  }
}

.modelIdCV {
  #modelIdCV-label {
    margin-left:4vw;
    width:14vw;
  }
}
.addUserTable {
  .MuiDialog-paperWidthSm {
    width: 45vw;
    height: 70vh;
    min-width: 45vw;
    max-width: 45vw;
    overflow: hidden;
  }
  .MuiDialogContent-root {
    padding: 1.04vw;
    &::-webkit-scrollbar {
      width: 0.8vw;         
      height: 0.8vw;     
  }
  &::-webkit-scrollbar-track {
      background: #E8EAEB;        
  }
    
  &::-webkit-scrollbar-thumb {
      background-color: #808080;    
      border-radius: 20px;       
  }
  }
  .MuiDialogTitle-root {
    padding: 0.78125vw;
    h2 {
      font-size: 1.25vw;
    }
  }
  table {
    height: 10%;
    width: 100%;
  }
  th {
    font-size: 1.09375vw;
    padding: 0.234375vw;
  }
  td {
    padding: 0.2vw;
    text-align: center;
    font-size: 1.09375vw;
  }
  .MuiTypography-h6 {
    button {
      float: right;
      padding: 0;
    }
  }
}

#assignRole-label {
  transform: translate(0, 1.5px) scale(0.75);
}

.user-formblank {
  width: 30%;
  background: #0c629b;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .icon-workspace {
    height: 11.7vw;
    width: 11.7vw;
    position: absolute;
    top: 20vw;
    left: 18vw;
  }
}

.userform-wrapper {
  width: 70%;
  font-family: Helvetica;
  background-color: #eff5f8;
}

.user-form {
  padding: 3%;
  margin-right: 3%;
  label {
    color: #0c629b;
  }
  .MuiInputBase-root {
    font-size: 1vw;
  }
  .MuiFormLabel-root {
    font-size: 1vw;
  }
  .MuiFormLabel-root {
    font-size: 1vw;
  }
  .add-users-icon {
    cursor: pointer;
    padding-left: 0.78vw;
  }
  .save-btn {
    margin-left: 90%;
    margin-top: -5%;
  }
  .action-btns {
    padding: 5vh 0;
    display: flex;
    justify-content: center;

    .next-btn {
      margin: 0 1.9vw 0 0;
      width: 8.4vw;
      height: 2.29vw;
      font-size: 0.9vw;
    }

    .cancel-btn {
      background: #ffffff;
      border: 0.05vw solid #0c629b;
      border-radius: 0.15vw;
      width: 8.4vw;
      height: 2.29vw;
      padding: 0.15vw 0;
      font-size: 0.9vw;
      color: #0c629b;
    }
  }
  .users-bar {
    display: flex;
    margin-left: 1.09vw;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;

    .form-control {
      margin-top: 5%;
      border: transparent;
      border-bottom: 0.05vw solid;
      border-radius: 0;
      width: 20vw !important;
      padding: 0.26vw 0;
    }

    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      font-size: 1.2vw;
      .suggestions {
        width: 100%;
      }
    }

    .MuiFormControl-marginDense {
      width: 100%;
    }
  }
}

.addUserTable {
  .assign-role-dropdown {
    .MuiInputBase-root {
      margin-top: 1.4vw;
    }
    .MuiFormLabel-root {
      margin-top: 0.6vw;
      font-size: 0.9vw;
    }
    .MuiSelect-root {
      font-size: 1vw;
    }
  }

  .input-group {
    .suggestions {
      font-size: 1.2vw;
      .MuiInput-root {
        font-size: 1.1vw;
      }
    }
  }
}

.Link {
  span {
    cursor: pointer;
    text-decoration: underline;
    color: #0c629b;
    margin-left: 3%;
    font: normal normal bold 1.04vw Helvetica;
    line-height: 2;
  }
}

.create-text {
  text-align: center;
  font: normal normal bold 1.5vw Helvetica;
  color: #FFFFFF;
  margin-top: 4vw;
}

.next-btn {
  background: #0c629b;
  border: 0.05vw solid #0c629b;
  border-radius: 0.104vw;
  color: #ffffff;
  width: 0.009vw;
  height: 0.006vw;
  margin-right: 0.104vw;
}

p {
  display: block;
  margin-top: 0.003vw;
  margin-bottom: 0.003vw;
  margin-left: 0;
  margin-right: 0;
}

.addUserTable {
  .MuiFormControl-root {
    width: 13vw;
  }

  .MuiSvgIcon-root {
    color: #ffffff;
  }
  .MuiFormControl-marginDense {
    margin-left: 0 !important;
  }
}

.addUserTable .MuiDialog-paperWidthSm {
  height: 53vh;
  min-width: 45vw;
  overflow: hidden;
}

.OCRWeightage{
  label {
    font-size: 1.09375vw;
    }
}

.modal-content .MuiButton-root {
  margin-left: 0.03vw;
}

.modal-content {
  margin-left: 0.016vw;
}

.user-select-popup {
  .modal-body {
    padding: 0.41vw;
  }
  .modal-content label {
    font-weight: normal;
  }
}

